* {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

body {
    font-family: 'Roboto', 'sans-serif';
}

h1 {
    font-size: 4.8rem;
}

h2 {
    font-size: 3.2rem;
}

h3 {
    font-size: 2.8rem;
}

p {
    font-size: 1.6rem;
}

a {
    font-size: 1.6rem;
}

button {
    font-size: 1.6rem;
}
